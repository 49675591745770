import React from "react";
import { Button, Drawer } from "antd";
import { CommentOutlined, StarOutlined } from "@ant-design/icons";
import FeedbackForm from "./FeedbackForm";
import ReportFormComp from "../ReportFormComp";

const FeedbackButton: React.FC<{
  siteId: string | undefined;
  uniqueId: string | undefined;
}> = ({ siteId, uniqueId }) => {
  const buttonStyle: React.CSSProperties = {
    // position: "fixed",
    // right: "10px", // Adjust this value to position it closer to the scrollbar
    // top: "80%",
    // transform: "translateY(-50%) rotate(90deg)", // Rotate the button 90 degrees
    // zIndex: 1000, // Ensure it stays on top of other elements
    // transformOrigin: "right center", // Adjust the origin to keep it near the scrollbar
  };

  const [open, setOpen] = React.useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        type="link"
        icon={<StarOutlined />}
        style={buttonStyle}
        onClick={showDrawer}
      >
        Feedback
      </Button>
      <Drawer title="Feedback" onClose={onClose} open={open}>
        <FeedbackForm siteId={siteId || uniqueId} />
      </Drawer>
    </>
  );
};

export default FeedbackButton;
