import { Drawer, Space, Divider, Button, Typography, Tag } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import React from "react";
import { getData } from "../../CiscoConnector/ciscoCalls/get";
import { ciscoAccountType } from "../../CiscoConnector";
import { LoadingOutlined } from "@ant-design/icons";

const { Text } = Typography;

interface ConnectorTypes {
  uuid: string;
}

type SubProps = {
  record: ConnectorTypes;
  ciscoAccount: ciscoAccountType;
  endpoint: string;
  id_name: string;
  label: string;
};

const DynamicAction: FC<SubProps> = ({
  record,
  ciscoAccount,
  label,
  endpoint,
  id_name,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [buttonText, setButtonText] = useState("Copy");

  console.log({ data, error });

  const onClick = useCallback((text: string) => {
    setButtonText("Copied!");
    navigator.clipboard.writeText(text);
    setTimeout(() => {
      setButtonText("Copy");
    }, 2000);
  }, []);

  const getEndpoint = async () => {
    setLoading(true);
    // // Call the API with the API key
    const response = await getData(endpoint, {
      user: ciscoAccount.username,
      password: ciscoAccount.password,
      [id_name]: record[id_name],
    });
    response.data && setData(response.data);
    response.error && setError(response.error);
    setLoading(false);
  };

  const handleEndpoint = () => {
    setOpen(true);
    getEndpoint();
  };

  return (
    <>
      <a onClick={() => handleEndpoint()}>{label}</a>
      <Drawer
        title={label}
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
        width={"50%"}
        extra={
          <Space>
            <Button
              style={{ width: 60 }}
              onClick={() =>
                onClick(
                  typeof data === "string"
                    ? data
                    : JSON.stringify(data, null, 2)
                )
              }
              disabled={!data}
            >
              {buttonText}
            </Button>
          </Space>
        }
      >
        <pre>
          <Text>
            {loading ? (
              <Space direction="horizontal" size={"small"}>
                <LoadingOutlined />
                {"fething data..."}
              </Space>
            ) : typeof data === "string" ? (
              data
            ) : (
              JSON.stringify(data, null, 2)
            )}
          </Text>
        </pre>
      </Drawer>
    </>
  );
};

export default DynamicAction;
