import React, { useState } from "react";
import { Form, Input, Button, Result, Rate, Typography, message } from "antd";
import { CommentOutlined, NotificationFilled } from "@ant-design/icons";
import axios from "axios";

const { TextArea } = Input;
const { Text } = Typography;

const FeedbackForm: React.FC<{ siteId: string | undefined }> = ({ siteId }) => {
  const [form] = Form.useForm();
  const [value, setValue] = React.useState(3);
  // const desc = ["didn't work", "bad", "normal", "good", "excelent"];

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const userEIN = user?.uin;
  const account = JSON.parse(localStorage.getItem("currentAccount") || "{}");
  const accountName = account?.name;
  const access_token = user?.access_token;

  const [submitted, setSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const sendFeedback = async (values: any): Promise<void> => {
    setLoading(true);
    const body = {
      rating: `${values.rating}`,
      comment: `${values.comment}`,
      EIN: `${userEIN}`,
    };

    try {
      const response = await axios.post<any>(
        `/api/v1/ai_feedback/${accountName}/${values.site_id}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log(response.data);
        setSubmitted(true);
      } else console.log("Something went wrong!");
    } catch (error) {
      console.log({ error });
      message.error(`Upload failed. ${error?.response?.data?.error}`);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = (values: any) => {
    console.log("Received values from form: ", values);
    // Handle form submission, e.g., send data to a server
    // setSubmitted(true); // remove this line when uncomment the sendFeedback
    sendFeedback(values);
  };

  const handleReset = () => {
    form.resetFields();
    setSubmitted(false);
  };

  return (
    <div>
      {submitted ? (
        <FeedbackSuccess onReset={handleReset} />
      ) : (
        <Form
          form={form}
          name="feedback"
          layout="vertical"
          onFinish={onFinish}
          style={{ maxWidth: "400px", margin: "0 auto" }}
        >
          <Form.Item
            name="rating"
            label="Rating"
            rules={[{ required: true, message: "Please rate" }]}
          >
            <Rate
              // tooltips={desc}
              onChange={setValue}
              value={value}
              disabled={loading}
            />
            {/* {value ? <span>{desc[value - 1]}</span> : null} */}
          </Form.Item>

          <Form.Item
            name="comment"
            label="Comment"
            rules={[{ required: true, message: "Please enter your feedback" }]}
          >
            <TextArea
              disabled={loading}
              rows={4}
              placeholder="Enter your feedback"
            />
          </Form.Item>

          <Form.Item>
            <Button block type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form.Item>

          <Form.Item>
            <Text type="secondary">{`Customer: ${accountName} `}</Text> <br />
            <Text type="secondary">{`Site ID: ${siteId}`}</Text> <br />
            <Text type="secondary">{`Update by: ${userEIN}`}</Text>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default FeedbackForm;

const FeedbackSuccess: React.FC<{ onReset: () => void }> = ({ onReset }) => {
  return (
    <Result
      status="success"
      title="Feedback Sent Successfully"
      subTitle="Thank you for your feedback. We appreciate your input!"
      extra={[
        <Button type="primary" key="home" onClick={onReset}>
          Submit Another Feedback
        </Button>,
      ]}
    />
  );
};

// site_id = event['headers'].get("site_id")
// customer = event['headers'].get("account")
// last_update_by = event['headers'].get("EIN")
// rating = event['headers'].get('rating')
// event['body']))['comment']
