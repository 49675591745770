import React, { useState } from "react";
import dayjs from "dayjs";

import type { TimeRangePickerProps } from "antd";
import {
  Button,
  DatePicker,
  Flex,
  Form,
  notification,
  Select,
  Tag,
  TimePicker,
} from "antd";
import { useTables } from "../../../../../contexts/TablesContext";
import ColourSlider from "./ColourSlider";
import { getData } from "../../functions/paloAltoCalls/get";

export interface ApiResponse {
  data?: any | null;
  error?: {
    response?: {
      data?: {
        error?: string;
      } | null;
    } | null;
  } | null;
}

const FormContent = ({
  paloAltoKey,
  data,
  form,
  reportMode,
  setReportMode,
  setMyQueueId,
  setShowQueue,
}) => {
  const [api, contextHolder] = notification.useNotification();

  const tables = useTables();
  const selectedIds = tables.selectedTablesRowId;
  const siteIds = selectedIds?.Sites;
  console.log({ siteIds });

  const siteNames = siteIds ? findSiteNames(siteIds, data) : [];

  const [initialValues, setInitialValues] = useState({
    sitelist: siteIds || [],
    reportMode: "generate_capacity_report",
    reportType: "throughput",
    reportStats: "avg",
    filtertype: "include",
    Working_Hours: 1,
    st: "",
    et: "",
    workingHours: [dayjs("8:00:00", "HH:mm:ss"), dayjs("18:00:00", "HH:mm:ss")],
    LT: 1,
    emails: [],
    timeperiod: "1hr",
  });

  const [loading, setLoading] = useState(false);

  // Colours Threshols Values
  type Strings = [string, string, string, string];
  const [thresholdValues, setThresholdValues] = useState([0, 15, 50, 75, 100]);
  const [coloursHex, setColoursHex] = useState<Strings>([
    "#0000FF", // blue
    "#00FF00", // green
    "#FFFF00", // yellow
    "#FF0000", // red
  ]);

  const onFinish = async (values: any) => {
    console.log({ values });

    const user = JSON.parse(localStorage.getItem("user"));

    const Working_Hours = values.Working_Hours;
    const workingHours_Start =
      Working_Hours.lentght > 0 ? values.workingHours[0] : null;
    const workingHours_End =
      Working_Hours.lentght > 0 ? values.workingHours[1] : null;

    const payload = {
      sitelist: siteIds,
      st: values.dateRange[0],
      et: values.dateRange[1],
      Working_Hours,
      workingHours_Start,
      workingHours_End,
      filtertype: values.filtertype,
      created_by: user?.email,
      LT: values.LT,
      ReportParams: values.reportStats,
      ReportType: values.reportType,
      timeperiod: values.timeperiod,
      Low_Colour: coloursHex[0].replace("#", ""),
      Low_Val: thresholdValues[0],
      OK_Colour: coloursHex[1].replace("#", ""),
      OK_Val: thresholdValues[1],
      Warn_Colour: coloursHex[2].replace("#", ""),
      Warn_Val: thresholdValues[2],
      Crit_Colour: coloursHex[3].replace("#", ""),
      Crit_Val: thresholdValues[3],
    };
    console.log({ payload });
    setLoading(true);
    const response: ApiResponse = await getData(values.reportMode, {
      api_key: paloAltoKey,
      ...payload,
    });
    console.log({ response });
    if (response.data) {
      setMyQueueId(response.data);
      setShowQueue(true);
    } else if (response.error) {
      api.error({
        message: "Something went wrong!",
        description: `${response.error?.response?.data?.error}` || "",
        showProgress: true,
        pauseOnHover: true,
      });
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleReportOption = (value: string) => {
    setReportMode(value);
  };

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "This Month", value: [dayjs().startOf("M"), dayjs()] },
    {
      label: "Last Month",
      value: [
        dayjs().startOf("M").subtract(1, "M"),
        dayjs().endOf("M").subtract(1, "M"),
      ],
    },
    { label: "Last 7 Days", value: [dayjs().subtract(7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().subtract(14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().subtract(30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().subtract(90, "d"), dayjs()] },
    { label: "Last 12 Months", value: [dayjs().subtract(1, "y"), dayjs()] },
  ];

  const hourType = Form.useWatch("Working_Hours", form);

  return (
    <>
      {contextHolder}

      <Form
        layout="vertical"
        form={form}
        initialValues={{ ...initialValues }}
        style={{ maxWidth: "100%" }}
        size="small"
        name="palo_alto_reports"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelWrap
      >
        <Form.Item name="sitelist" label="Selected Sites">
          {siteNames.length > 0 ? (
            <Flex gap="8px 0" wrap>
              {siteNames.map((siteName: string, index: number) => (
                <Tag key={index} bordered={false} color="purple">
                  {siteName}
                </Tag>
              ))}
            </Flex>
          ) : (
            "All Sites"
          )}
        </Form.Item>

        <Form.Item label="Select Report" name="reportMode">
          <Select
            onChange={handleReportOption}
            options={[
              {
                value: "generate_capacity_report",
                label: <span>Capacity Report</span>,
              },
              {
                value: "generate_heatmap_report",
                label: <span>Heatmap Report</span>,
              },
            ]}
          />
        </Form.Item>

        <Form.Item name="filtertype" label="Include/Exclude Selected Sites">
          <Select
            disabled={siteNames.length === 0}
            options={[
              {
                value: "include",
                label: <span>Include Selected Sites</span>,
              },
              {
                value: "exclude",
                label: <span>Exclude Selected Sites</span>,
              },
            ]}
          />
        </Form.Item>

        {reportMode === "generate_heatmap_report" && (
          <>
            <Form.Item name="reportType" label="Report Type">
              <Select
                options={[
                  {
                    value: "throughput",
                    label: <span>Throughput</span>,
                  },
                  {
                    value: "utilization",
                    label: <span>Utilization</span>,
                  },
                  {
                    value: "latency",
                    label: <span>Latency</span>,
                  },
                  {
                    value: "jitter",
                    label: <span>Jitter</span>,
                  },
                  {
                    value: "packetLoss",
                    label: <span>PacketLoss</span>,
                  },
                  {
                    value: "cpu",
                    label: <span>CPU</span>,
                  },
                  {
                    value: "memory",
                    label: <span>Memory</span>,
                  },
                  {
                    value: "disk",
                    label: <span>Disk</span>,
                  },
                ]}
              />
            </Form.Item>

            <Form.Item name="reportStats" label="Report Statistic">
              <Select
                options={[
                  {
                    value: "avg",
                    label: <span>Avg</span>,
                  },
                  {
                    value: "max",
                    label: <span>Max</span>,
                  },
                  {
                    value: "95th",
                    label: <span>95th</span>,
                  },
                ]}
              />
            </Form.Item>
          </>
        )}

        <Form.Item
          name="dateRange"
          label="Date Range"
          rules={[{ required: true, message: "Please choose the date range" }]}
        >
          <DatePicker.RangePicker
            presets={rangePresets}
            style={{ width: "100%" }}
            maxDate={dayjs()}
            // getPopupContainer={(trigger) => trigger.parentElement!}
          />
        </Form.Item>

        <Form.Item name="Working_Hours" label="Time Range">
          <Select
            options={[
              {
                value: 1,
                label: <span>Working Hours</span>,
              },
              {
                value: 0,
                label: <span>24 Hours</span>,
              },
            ]}
          />
        </Form.Item>

        {hourType === 1 && (
          <Form.Item name="workingHours">
            <TimePicker.RangePicker format={"HH"} style={{ width: "100%" }} />
          </Form.Item>
        )}

        {reportMode === "generate_heatmap_report" && (
          <Form.Item name="timeperiod" label={"Time Interval"}>
            <Select
              options={[
                {
                  value: "1hr",
                  label: <span>1 Hour</span>,
                },
                {
                  value: "2hr",
                  label: <span>2 Hours</span>,
                },
                {
                  value: "4hr",
                  label: <span>4 Hours</span>,
                },
                {
                  value: "6hr",
                  label: <span>6 Hours</span>,
                },
              ]}
            />
          </Form.Item>
        )}

        <Form.Item name="LT" label={"Local Time?"}>
          <Select
            options={[
              { value: 1, label: <span>yes</span> },
              { value: 0, label: <span>no</span> },
            ]}
          />
        </Form.Item>

        {reportMode === "generate_heatmap_report" && (
          <Form.Item name="colours" label={"Colours & Thresholds"}>
            <ColourSlider
              thresholdValues={thresholdValues}
              setThresholdValues={setThresholdValues}
              coloursHex={coloursHex}
              setColoursHex={setColoursHex}
            />
          </Form.Item>
        )}

        {/*   
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="recipents"
            label="Report Recipients"
            rules={[
              {
                required: true,
                message: "Please Enter Report Recipients",
              },
            ]}
          >
            <Form.List name="emails">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 32 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name]}
                        rules={[
                          {
                            required: true,
                            type: "email",
                            message: "Please enter a valid email",
                          },
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add Recipient
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        </Col>
      </Row> */}

        <Form.Item>
          <Button loading={loading} block type="primary" htmlType="submit">
            Generate Report
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default FormContent;

function findSiteNames(siteIds: string[], data: any[]): string[] {
  const siteNames: string[] = [];

  data.forEach((item) => {
    item.data.forEach((entry: any) => {
      if (siteIds.includes(entry.id)) {
        siteNames.push(entry.name);
      }
    });
  });

  return siteNames;
}
