import {
  Button,
  Card,
  Flex,
  Form,
  Input,
  Space,
  Tabs,
  notification,
  Typography,
  Divider,
} from "antd";

import React, { useState } from "react";
import { ReloadOutlined } from "@ant-design/icons";
import { BorderedContent } from "../Common/Components/BorderedContent";
import TabPage from "../Common/Components/TabPage";
import { getData } from "./functions/paloAltoCalls/get";
import { useTables } from "../../../contexts/TablesContext";
import ExportToExcel from "../Common/Components/Exports/ExportToExcel";
import Reports from "./Reports";

const { Text } = Typography;

export type ciscoAccountType = {
  username: string;
  password: string;
};

const PaloAltoConnector = () => {
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(false);
  const [paloAltoKey, setPaloAltoKey] = useState<string | undefined>(undefined);
  const [mainLabel, setMainLabel] = useState<string>("");

  const reset = () => {
    setData([]);
    setPaloAltoKey(undefined);
  };

  const [form] = Form.useForm<{ paloAltoToken: string }>();
  const paloAltoTokenInput = Form.useWatch("paloAltoToken", form);

  const getUI = async (paloAltoToken: string) => {
    setLoading(true);
    setPaloAltoKey(paloAltoToken);
    // // Call the API with the API key
    const response = await getData("get_ui", {
      api_key: paloAltoToken,
    });
    if (response.data) {
      setData(response.data);
      const label = response.data?.find((item) => item.name === "MainLabel");
      if (label) {
        setMainLabel(label.data[0].name);
      }
    }

    response.error && setError(response.error);

    setLoading(false);
    console.log({ response });
    response.error &&
      api.error({
        message: "Something went wrong!",
        description: `${response.error?.response?.data?.error}` || "",
        showProgress: true,
        pauseOnHover: true,
      });
  };
  const onFinish = async (values: any) => {
    getUI(values.paloAltoToken);
  };

  const payloads = {};
  data.forEach(({ name, data }) => {
    payloads[name] = data;
  });
  console.log({ payloads });

  const operations = (
    <>
      <Reports data={data} paloAltoKey={paloAltoKey} />
      <Divider type="vertical" />
      <ExportToExcel payloads={payloads} />
      {/* <Divider type="vertical" />
      <Button
        size="small"
        icon={<ReloadOutlined />}
        onClick={() => paloAltoKey && getUI(paloAltoKey)}
      >
        Reload
      </Button> */}
    </>
  );

  const paloAltoForm = (
    <Form
      form={form}
      name="connect"
      onFinish={onFinish}
      autoComplete="off"
      // layout={"inline"}
    >
      <Form.Item name="paloAltoToken">
        <Input.Password placeholder="Enter your PA Access Token" />
      </Form.Item>
      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
          disabled={!paloAltoTokenInput}
          loading={loading}
        >
          {loading ? "Connecting" : "Connect"}
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <Flex vertical gap="middle" style={{ width: "100%" }}>
      {contextHolder}

      <h1 className="colorIndigo" style={{ marginBottom: 16 }}>
        Palo Alto Connector
      </h1>
      {!data.length ? (
        paloAltoForm
      ) : (
        <Card
          title={
            <Space>
              <>{mainLabel}</>
            </Space>
          }
          extra={
            paloAltoKey && (
              <Flex align="flex-end" gap="middle">
                <small
                  style={{ marginLeft: 8 }}
                >{`Connected with token ***** ${paloAltoKey?.slice(
                  -4,
                  paloAltoKey.length
                )} `}</small>
                <Button size="small" type="text" onClick={() => reset()}>
                  <Text type="danger">
                    <small>{"Disconnect"}</small>
                  </Text>
                </Button>
              </Flex>
            )
          }
        >
          <Tabs
            tabBarExtraContent={operations}
            type="card"
            defaultActiveKey="1"
            items={data.map(
              ({ name, columns, data, actions = null, hide = false }, index) =>
                !hide && {
                  key: `${index + 1}`,
                  label: name,
                  children: (
                    <BorderedContent>
                      <TabPage
                        name={name}
                        columns={columns}
                        data={data}
                        actions={actions}
                        account={paloAltoKey}
                      />
                    </BorderedContent>
                  ),
                }
            )}
            size="large"
          />
        </Card>
      )}
    </Flex>
  );
};

export default PaloAltoConnector;
