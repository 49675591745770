import { Flex, Card, Statistic, Collapse, Tag, Typography } from "antd";
import React, { useState, useEffect } from "react";

const { Text } = Typography;

interface StatsCardProps {
  formData: any;
  changedValues: Record<string, any>;
  userAddedAttributes: number;
}

const StatsCard: React.FC<StatsCardProps> = ({
  formData,
  changedValues,
  userAddedAttributes,
}) => {
  const CardStat: React.FC<{
    title: string;
    value: any;
    valueColor?: string;
  }> = ({ title, value, valueColor }) => {
    const cardWidth = "12rem";
    const cardSize = "small";
    return (
      <Card size={cardSize} style={{ width: cardWidth }}>
        <Statistic
          valueStyle={{ color: valueColor }}
          title={<small>{title}</small>}
          value={value}
        />
      </Card>
    );
  };

  return (
    <Flex vertical gap="large" align="flex-start" justify="flex-start">
      <CardStat
        title="AI Extracted Values"
        value={formData?.reports?.filled_values}
      />
      <CardStat
        title="Missing Values"
        value={formData?.reports?.empty_values}
        valueColor="#ff5a4b"
      />
      <CardStat
        title="User Edited Values"
        value={Object.entries(changedValues).length}
        valueColor="#ff80ff"
      />
      <CardStat
        title="User Added Attributes"
        value={userAddedAttributes}
        valueColor="#1ec8e6"
      />
      <Collapse
        size="small"
        items={Object.entries(formData?.reports?.source_stats).map(
          ([filename, filedata]) => ({
            key: filename,
            label: <small>{`${filename} (${filedata.length} values)`}</small>,
            children: (
              <Flex gap="4px 0" wrap>
                {filedata.map((item, index) => (
                  <Tag color="purple" key={index}>
                    {item}
                  </Tag>
                ))}
              </Flex>
            ),
          })
        )}
      />
    </Flex>
  );
};

export default StatsCard;
