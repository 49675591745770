import React, { useEffect, useState } from "react";
import type { MenuProps, TabsProps } from "antd";
import { Button, Drawer, Dropdown, Flex, message, Select, Tabs } from "antd";
import {
  CloudSyncOutlined,
  CodeOutlined,
  ExportOutlined,
  FileSyncOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { exportAsExcel } from "../../../../utils/exportAsExcel";
import AiTemplates from "./AiTemplates";

const user = JSON.parse(localStorage.getItem("user") || "{}");
const userEIN = user?.uin;
const account = JSON.parse(localStorage.getItem("currentAccount") || "{}");
const accountName = account?.name;
const access_token = user?.access_token;

const ButtonDrop: React.FC<{
  original: any;
  data: any;
  siteId: string;
}> = ({ original, data, siteId }) => {
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(undefined);

  const [openRaw, setOpenRaw] = useState(false);
  const [openTemplate, setOpenTemplate] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const onMenuClick: MenuProps["onClick"] = (e) => {
    console.log("click", e);
    setClicked(e.key);

    if (e.key === "1") {
      setOpenRaw(!openRaw);
    } else if (e.key === "2") {
      exportToExcel();
    } else if (e.key === "3") {
      setOpenTemplate(!openTemplate);
    }
  };

  const items = [
    {
      key: "1",
      label: <small>Display Raw</small>,
      icon: <CodeOutlined />,
    },
    {
      key: "2",
      label: <small>Export to Excel</small>,
      icon: <ExportOutlined />,
    },
    {
      key: "3",
      label: <small>Generate Template</small>,
      icon: <FileSyncOutlined />,
    },
  ];

  const exportToExcel = async () => {
    messageApi.open({
      type: "loading",
      content: "Export in progress..",
      duration: 0,
    });
    const isCreated = await exportAsExcel({
      ...data?.attributes,
    });
    isCreated
      ? console.log("Exported Successfully")
      : console.log("Failed Download");
    messageApi.destroy();
  };

  const saveChanges = async (updatedData: any): Promise<void> => {
    console.log({ updatedData });
    setLoading(true);
    try {
      const response = await axios.post<any>(
        `/api/v1/ai_data_verfiy_edit/${userEIN}/${accountName}/${siteId}`,
        { data: updatedData },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log(response.data);
        message.success("Saved successfully");
      } else console.log("Something went wrong!");
    } catch (error) {
      console.log({ error });
      message.error(`Upload failed. ${error?.response?.data?.title}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Dropdown.Button
        type="text"
        size="small"
        menu={{ items, onClick: onMenuClick }}
        loading={loading}
        onClick={() => saveChanges(data)}
      >
        <Flex gap="small">
          {!loading && <CloudSyncOutlined />}
          <small>Save</small>
        </Flex>
      </Dropdown.Button>

      <Drawer
        title="Raw Data"
        onClose={() => setOpenRaw(false)}
        open={openRaw}
        destroyOnClose
      >
        {data === original ? (
          <pre>{JSON.stringify(data, null, 2)}</pre>
        ) : (
          <Tabs
            size="small"
            defaultActiveKey="1"
            items={[
              {
                key: "current",
                label: "Current",
                children: <pre>{JSON.stringify(data, null, 2)}</pre>,
              },
              {
                key: "original",
                label: "Original",
                children: <pre>{JSON.stringify(original, null, 2)}</pre>,
              },
            ]}
          />
        )}
      </Drawer>

      <AiTemplates
        openTemplate={openTemplate}
        setOpenTemplate={setOpenTemplate}
        formData={data}
      />
    </>
  );
};
export default ButtonDrop;
