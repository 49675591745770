import { Button, Card, Flex, Input, Select, Typography } from "antd";
import Meta from "antd/es/card/Meta";
import React, { ChangeEvent, useState } from "react";

const { Text } = Typography;

const CardComp = ({
  icon,
  title,
  inputPlaceholder,
  buttonText,
  onButtonClick,
  onInputBlur,
  disabled,
  disabledButton,
  setProduct,
  setSubProduct,
  product,
  subProduct,
  inputValue,
}: {
  icon: React.ReactElement;
  title: string;
  inputPlaceholder: string;
  buttonText: string;
  onInputBlur: (value: any) => void;
  onButtonClick: () => void;
  disabled: boolean;
  disabledButton: boolean;
  product: string | undefined;
  subProduct: string | undefined;
  setProduct: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSubProduct: React.Dispatch<React.SetStateAction<string | undefined>>;
  inputValue: string | undefined;
}) => {
  const color = disabled ? "lightgray" : "#5514b4";
  const [value, setValue] = useState<string | undefined>(undefined);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onInputBlur(e.target.value);
  };

  return (
    <Card hoverable={!disabled} style={{ cursor: "default" }}>
      <Flex gap="small" vertical align="center" justify="center">
        {React.cloneElement(icon, {
          style: { fontSize: 40, color },
        })}
        <Text style={{ color }}>{title}</Text>

        <Input
          placeholder={inputPlaceholder}
          onChange={handleChange}
          disabled={disabled}
          value={inputValue}
        />

        {title === "Upload Files" && (
          <>
            <Select
              allowClear
              showSearch
              placeholder="Product"
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[{ value: "SDWAN", label: "SDWAN" }]}
              onChange={(value) => setProduct(value)}
              value={product && product}
              disabled
            />

            <Select
              allowClear
              showSearch
              placeholder="Sub-product"
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                { value: "CISCO", label: "CISCO" },
                { value: "FORTINET", label: "FORTINET" },
                { value: "PALO ALTO", label: "PALO ALTO" },
              ]}
              onChange={(value) => setSubProduct(value)}
              value={subProduct && subProduct}
            />
          </>
        )}

        <Button
          type="primary"
          block
          onClick={onButtonClick}
          disabled={disabledButton || disabled}
        >
          {buttonText}
        </Button>
      </Flex>
    </Card>
  );
};

export default CardComp;
