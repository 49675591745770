import React, { useCallback, useEffect, useState } from "react";
import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  CopyOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  Flex,
  Input,
  message,
  Radio,
  Row,
  Space,
  Typography,
  Upload,
} from "antd";
import type { RadioChangeEvent, UploadFile, UploadProps } from "antd";
import axios from "axios";
import { callApiConnector } from "../../Connectors/Common/functions/_connector_call";
import { exportAsExcel } from "../../../utils/exportAsExcel";
import TempBanner from "../TempBanner";
import Dragger from "antd/es/upload/Dragger";
import RetrievedData from "../RetrievedDataComp";

interface UploadResponse {
  message: string;
  siteId: string;
  file_names: string[];
}

const UploadFilesComp = ({
  response,
  setResponse,
  uploading,
  setUploading,
  fileList,
  siteId,
  retrievalId,
  product,
  subProduct,
  setFileList,
  reset,
}: {
  reset: () => void;
  response: UploadResponse | undefined;
  setResponse: React.Dispatch<React.SetStateAction<UploadResponse | undefined>>;
  uploading: boolean;
  setUploading: React.Dispatch<React.SetStateAction<boolean>>;
  fileList: UploadFile[];
  siteId: string | undefined;
  retrievalId: string | undefined;
  product: string | undefined;
  subProduct: string | undefined;
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  setAction: React.Dispatch<React.SetStateAction<string>>;
  setSiteId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setRetrievalId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setProduct: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSubProduct: React.Dispatch<React.SetStateAction<string | undefined>>;
  setResponseData: React.Dispatch<
    React.SetStateAction<
      | {
          gen_ai: any;
          error: string;
        }
      | undefined
    >
  >;
}) => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const account = JSON.parse(localStorage.getItem("currentAccount") || "{}");
  const access_token = user?.access_token;

  const uploadFiles = async (formData: FormData): Promise<void> => {
    try {
      const response = await axios.post<UploadResponse>(
        "/api/v1/ai_data_extract",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      if (response.status === 200) {
        setResponse(response.data);
      } else message.warning("Something went wrong!");
    } catch (error) {
      console.log({ error });
      message.error(`Upload failed. ${error?.response?.data?.error}`);
    } finally {
      setUploading(false);
    }
  };

  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files", file.originFileObj as File);
    });

    console.log({ user });
    console.log(`${user?.uin}`);

    formData.append("site_id", `${siteId}` as string);
    formData.append("account_name", `${account?.name}` as string);
    formData.append("account_id", `${account?.id}` as string);
    formData.append("unique_id", `${retrievalId}` as string);
    formData.append("product", `${product}` as string);
    formData.append("sub_product", `${subProduct}` as string);
    formData.append("ein", `${user?.uin}` as string);

    setUploading(true);
    uploadFiles(formData);
  };

  const directoryProps = {
    name: "directory",
    directory: true,
    onRemove: (file) => {
      const newFileList = fileList.filter((item) => item.uid !== file.uid);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList((prevList) => [...prevList, file]);
      return false; // Prevent automatic upload
    },
    onChange: (info) => {
      setFileList(info.fileList);
    },
    fileList,
  };

  const fileProps = {
    name: "file",
    multiple: true,
    onRemove: (file) => {
      const newFileList = fileList.filter((item) => item.uid !== file.uid);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList((prevList) => [...prevList, file]);
      return false; // Prevent automatic upload
    },
    onChange: (info) => {
      setFileList(info.fileList);
    },
    fileList,
  };

  const props: UploadProps = {
    name: "unstructured",
    directory: true,
    multiple: true,
    onRemove: (file) => {
      const newFileList = fileList.filter((item) => item.uid !== file.uid);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList((prevList) => [...prevList, file]);
      return false; // Prevent automatic upload
    },
    onChange: (info) => {
      setFileList(info.fileList);
    },
    fileList,
  };

  const [value, setValue] = useState("files");
  const onChange = (e: RadioChangeEvent) => {
    // console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  return (
    <>
      {!response ? (
        <>
          <Card>
            <Flex vertical gap="middle" style={{ width: "100vh" }}>
              <Space align="start">
                <Radio.Group
                  onChange={onChange}
                  value={value}
                  optionType="button"
                  buttonStyle="solid"
                >
                  <Space direction="vertical" style={{ width: "15vh" }}>
                    <Radio.Button value={"files"} style={{ width: "15vh" }}>
                      Files
                    </Radio.Button>
                    <Radio.Button value={"folder"} style={{ width: "15vh" }}>
                      Folder
                    </Radio.Button>
                  </Space>
                </Radio.Group>

                <Dragger
                  disabled={response || uploading}
                  {...(value === "files" ? fileProps : directoryProps)}
                  style={{ width: "84vh", height: "10px" }}
                >
                  <p className="ant-upload-drag-icon">
                    <Flex vertical align="center" justify="center">
                      <CloudUploadOutlined />
                      <small className="ant-upload-drag-icon">
                        Upload {value === "files" ? "Files" : "Folder"} <br />
                        {siteId} | {product} | {subProduct}
                      </small>
                    </Flex>
                  </p>
                  {/* <p style={{ color: "#5514b4" }}>Upload Files</p> */}
                  <p className="ant-upload-text">
                    Click or drag {value === "files" ? "files" : "a folder"} to
                    this area to upload
                  </p>
                  <p className="ant-upload-hint"></p>
                </Dragger>
              </Space>
              <Button
                block
                type="primary"
                onClick={handleUpload}
                // disabled={fileList.length === 0 || !siteId || response}
                loading={uploading}
              >
                {uploading ? "Uploading..." : "Send to Gen AI Gateway"}
              </Button>
            </Flex>
          </Card>
          <Button disabled={uploading} onClick={reset} danger type="text">
            Restart
          </Button>
        </>
      ) : (
        <RetrievedData
          retrieval_id={response?.unique_id}
          siteId={siteId}
          reset={reset}
        />
      )}
    </>
  );
};

export default UploadFilesComp;

{
  /* {response && (
          
          <>
            <Alert
              message={response?.result}
              description={
                <div>
                  {response?.result?.includes(
                    "Files uploaded successfully"
                  ) && (
                    <>
                      <p>
                        <strong>
                          Gen AI Gateway is processing your data.
                          <br />
                          This may take a couple of minutes. Please wait or
                          check back later using this Unique ID:
                          {response?.unique_id}
                        </strong>
                      </p>
                      <RetrievedData unique_id={response?.unique_id} />
                    </>
                  )}
                </div>
              }
              type={
                response?.message?.includes("Files uploaded successfully")
                  ? "success"
                  : "info"
              }
              showIcon
            />
          </>
        )} */
}
