import { Alert } from "antd";
import React from "react";

const TempBanner = () => {
  return (
    <Alert
      showIcon
      closable
      message={
        <small>
          <strong>Development in Progress:</strong> Temporary instabilities may
          occur. Thank you for understanding!
        </small>
      }
      type="warning"
    />
  );
};

export default TempBanner;
