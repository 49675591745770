import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Typography, Spin, message, Space } from "antd";
import { Flex } from "antd"; // Assuming Flex is a custom component or from a library
import StatsCard from "./StatsCard";
import FormDisplay from "./FormDisplay";

const { Text } = Typography;

const RetrievedData = ({ retrieval_id, siteId, reset }) => {
  const [formData, setFormData] = useState<RetrievedDataInterface | undefined>(
    undefined
  );
  const [originalData, setOriginalData] = useState<
    RetrievedDataInterface | undefined
  >(undefined);
  const [changedValues, setChangedValues] = useState({});
  const [userAddedAttributes, setUserAddedAttributes] = useState(0);
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const account = JSON.parse(localStorage.getItem("currentAccount") || "{}");
  const access_token = user?.access_token;

  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const url = `/api/v1/ai_data_retrieval/${account?.name}/${retrieval_id}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      console.log("Data retrieved:", response.data);

      if (response.status === 200) {
        setFormData(response.data);
        setOriginalData(response.data);
      } else {
        message.warning("Something went wrong!");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error(`Upload failed. ${error?.response?.data?.error}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [retrieval_id]);

  return (
    <Flex
      gap="large"
      align="flex-start"
      justify="center"
      style={{ width: "100%" }}
    >
      <Flex
        gap="middle"
        vertical
        align="center"
        justify="center"
        style={{ minWidth: "100%" }}
      >
        {loading && !formData ? (
          <>
            <Spin />
            <Text>{"Retrieving... "}</Text>
            <Text type={"secondary"}>Ref: {retrieval_id}</Text>
          </>
        ) : (
          <FormDisplay
            siteId={siteId || retrieval_id}
            originalData={originalData}
            formData={formData}
            setFormData={setFormData}
            changedValues={changedValues}
            setChangedValues={setChangedValues}
            userAddedAttributes={userAddedAttributes}
            setUserAddedAttributes={setUserAddedAttributes}
          />
        )}
        {!loading && (
          <Button disabled={loading} onClick={reset} danger type="text">
            Restart
          </Button>
        )}
      </Flex>
      {formData && (
        <StatsCard
          formData={formData}
          changedValues={changedValues}
          userAddedAttributes={userAddedAttributes}
        />
      )}
    </Flex>
  );
};

export default RetrievedData;

export interface RetrievedDataInterface {
  data: Data;
  status: string;
}

export interface Data {
  attributes: JsonObject;
  empty_values: number;
  filled_values: number;
  source_stats: JsonObject;
}

interface JsonObject {
  [key: string]: any;
}
