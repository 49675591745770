import React, { useEffect, useState } from "react";
import { ApiResponse } from "../FormContent";
import { getData } from "../../functions/paloAltoCalls/get";
import {
  Alert,
  Button,
  Card,
  DatePicker,
  Divider,
  Flex,
  Form,
  Modal,
  notification,
  Select,
  Space,
  Table,
  Tag,
  TimePicker,
} from "antd";
import { useGitLabConnectors } from "../../../../../contexts/Connectors/GitLabContext";

import { Tree } from "antd";
import type { GetProps } from "antd";
import { DownOutlined } from "@ant-design/icons";

const ReportQueue = ({ paloAltoKey, myQueueId }) => {
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [revoking, setRevoking] = useState(false);
  const [queue, setQueue] = useState<any>([]);
  console.log({ queue });

  const getQueue = async () => {
    setLoading(true);
    const response: ApiResponse = await getData("get_async_queue", {
      api_key: paloAltoKey,
    });
    console.log({ response });
    if (response.data) {
      setQueue(response.data);
    } else if (response.error) {
      api.error({
        message: "Something went wrong!",
        description: `${response.error?.response?.data?.error}` || "",
        showProgress: true,
        pauseOnHover: true,
      });
    }
    setLoading(false);
  };

  const revokeTaskById = async (id: string) => {
    setRevoking(true);
    const response: ApiResponse = await getData("delete_async_task_function", {
      api_key: paloAltoKey,
      taskid: id,
    });
    console.log({ response });
    if (response.data) {
      api.success({
        message: "Task Revoked!",
        description: `Task: ${id} has been deleted from queue`,
        showProgress: true,
        pauseOnHover: true,
      });
    } else if (response.error) {
      api.error({
        message: "Something went wrong!",
        description: `${response.error?.response?.data?.error}` || "",
        showProgress: true,
        pauseOnHover: true,
      });
    }
    setRevoking(false);
  };

  useEffect(() => {
    getQueue();
  }, []);

  const [resetGitlab, setResetGitlab] = useState(true);

  const resetGitList = () => {
    setResetGitlab(!resetGitlab);
  };

  const onRefresh = () => {
    getQueue();
    resetGitList();
  };

  return (
    <div>
      {contextHolder}
      {myQueueId && (
        <p>
          Your current task ID: <strong>{myQueueId}</strong>
        </p>
      )}
      {loading ? (
        <p>Checking reports queue...</p>
      ) : (
        <Flex vertical gap="small">
          {queue.length > 0 ? (
            <Space direction="vertical">
              <Button
                type="default"
                block
                size="small"
                onClick={() => onRefresh()}
              >
                Refresh
              </Button>
              {queue.map((entry: any) => (
                <Card size="small">
                  <p>
                    <small>
                      <Space>
                        <strong>Task ID:</strong> {entry.id}{" "}
                        {/* <Button
                          type="text"
                          size="small"
                          danger
                          loading={revoking}
                          onClick={() => revokeTaskById(entry.id)}  
                        >
                          Delete
                        </Button> */}
                      </Space>
                    </small>
                  </p>
                  <p>
                    <small>
                      <strong>Task Name:</strong> {entry.report_name}
                    </small>
                  </p>
                  <p>
                    <small>
                      <strong>Task Start Time:</strong> {entry.start_time}
                    </small>
                  </p>
                  <p>
                    <small>
                      <strong>Created by:</strong> {entry.created_by}
                    </small>
                  </p>
                  <p>
                    <small>
                      <strong>Status:</strong>{" "}
                      <Tag color="purple">{entry.status}</Tag>
                    </small>
                  </p>
                </Card>
              ))}
            </Space>
          ) : (
            <Alert
              message="Queue is empty!"
              showIcon
              description="The reports queue is currently empty, indicating that all jobs have been completed."
              type="info"
              action={
                <Button type="primary" size="small" onClick={() => getQueue()}>
                  Refresh
                </Button>
              }
            />
          )}
        </Flex>
      )}
      <Divider />
      <AllReports setResetGitlab={setResetGitlab} />
    </div>
  );
};

export default ReportQueue;

const AllReports = ({ setResetGitlab }) => {
  const gitlab = useGitLabConnectors();
  const files = gitlab.files;
  const loadingFiles = gitlab.loadingFiles;
  const errorFiles = gitlab.errorFiles;
  const list_files = gitlab.list_files;
  const downloadFile = gitlab.get_file;
  const loadingDownload = gitlab.loadingDownload;
  const gitRepo = "palo_alto_reports";

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("");
  const [downloadPath, setDownloadPath] = useState(null);

  type DirectoryTreeProps = GetProps<typeof Tree.DirectoryTree>;

  const { DirectoryTree } = Tree;

  useEffect(() => {
    // if (!files && !loadingFiles)
    list_files && list_files(gitRepo);
  }, [setResetGitlab]);

  const onSelect: DirectoryTreeProps["onSelect"] = (
    keys: string[],
    info: any
  ) => {
    // console.log('Trigger Select', keys, info.node);
    setModalText(info.node.title);
    setDownloadPath(info.node.path);
    setOpen(true);
  };

  useEffect(() => {
    setOpen(loadingDownload);
  }, [loadingDownload]);

  return (
    <>
      <p>Generated Reports</p>
      {files ? (
        <Tree
          showLine
          switcherIcon={<DownOutlined />}
          defaultExpandAll
          onSelect={onSelect}
          // onExpand={onExpand}
          treeData={files}
        />
      ) : (
        "Loading..."
      )}

      <Modal
        title="Proceed with download?"
        open={open}
        okText="Download"
        onOk={async () => await downloadFile(gitRepo, downloadPath)}
        confirmLoading={loadingDownload}
        onCancel={() => setOpen(false)}
        cancelButtonProps={{ disabled: true }}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
};
