import {
  Drawer,
  Flex,
  Select,
  Button,
  Space,
  Spin,
  Typography,
  Switch,
  Divider,
  message,
} from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { dynamicSort } from "../../../../utils/sorter";
import { DeleteOutlined } from "@ant-design/icons";
import {
  handleHttpError,
  multipleDocuments,
} from "../../../../../common/utils";
import Cookies from "universal-cookie";

const { Title, Text } = Typography;

export interface LibrariesType {
  id: number;
  name: string;
  scope_required?: string;
  value?: number;
  label?: string;
}

export interface LabelsType {
  description: string;
  id: number;
  label: string;
}

export interface TemplatesType {
  content_type: string;
  example_dcf: boolean;
  extension: string;
  id: number;
  library: LibrariesType;
  name: string;
  type: string;
  account_id?: number;
  label?: LabelsType;
}

export interface OptionList {
  value: number;
  label: string;
}

const account = JSON.parse(localStorage.getItem("currentAccount") || "{}");

const user = JSON.parse(localStorage.getItem("user") || "{}");

const AiTemplates = ({ openTemplate, setOpenTemplate, formData }) => {
  const [loadingLibs, setLoadingLibs] = useState<boolean>(false);
  const [libraries, setLibraries] = useState<OptionList[]>([]);
  const [libraryId, setLibraryId] = useState<number>(0);

  const [labels, setLabels] = useState<OptionList[]>([
    { value: 0, label: "All Labels" },
  ]);
  const [labelId, setLabelId] = useState<number>(0);

  const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false);
  const [templates, setTemplates] = useState<TemplatesType[]>([]);
  const [templateOptions, setTemplateOptions] = useState<OptionList[]>([]);
  const [selectedTemplates, setSelectedTemplates] = useState<any>([]);

  const [isAccountOnly, setIsAccountOnly] = useState<boolean>(true);

  const fullReset = () => {
    // setLibraryId(0);
    // setLabelId(0);
    // setLibraries([]);
    // setTemplateOptions([]);
    // setIsAccountOnly(true);
    // setSelectedTemplates([]);
  };

  useEffect(() => {
    const getLibraries = async () => {
      setLoadingLibs(true);
      const librariesResponse = await listLibraries();
      const data = librariesResponse.data;
      if (data) {
        const libraryList = data
          .map((library: any) => ({
            value: library.id,
            label: library.name,
          }))
          ?.sort(dynamicSort("label"));
        setLibraries(libraryList);
      }
      setLoadingLibs(false);
    };
    libraries.length === 0 && getLibraries();
  }, []);

  const defineLabels = useCallback((data: any) => {
    const seenIds = new Set();
    const labelList = data?.reduce((acc, item) => {
      if (item.label && !seenIds.has(item.label.id)) {
        seenIds.add(item.label.id);
        acc.push({
          value: item.label.id,
          label: item.label.label,
        });
      }
      return acc;
    }, []);

    setLabels((prevLabels) => {
      const newLabels = [
        { value: 0, label: "All Labels" },
        ...labelList.sort(dynamicSort("label")),
      ];
      // Only update state if labels have changed
      if (JSON.stringify(prevLabels) !== JSON.stringify(newLabels)) {
        return newLabels;
      }
      return prevLabels;
    });
  }, []);

  useEffect(() => {
    const templatesList = () => {
      let filteredTemplates = templates;

      if (isAccountOnly && account?.id) {
        filteredTemplates = templates.filter(
          (template: TemplatesType) => template.account_id == account.id
        );
      }

      defineLabels(filteredTemplates);

      return filteredTemplates.map((template: TemplatesType) => ({
        value: template.id,
        label: template.name,
      }));
    };

    setTemplateOptions((prevOptions) => {
      const newOptions = templatesList();
      // Only update state if options have changed
      if (JSON.stringify(prevOptions) !== JSON.stringify(newOptions)) {
        return newOptions;
      }
      return prevOptions;
    });
  }, [templates, isAccountOnly]);

  const getTemplates = async (library_id: number) => {
    setLoadingTemplates(true);
    const templatesResponse = await listTemplates(library_id);
    const data = templatesResponse.data;
    if (data) {
      setTemplates(data);
    }

    setLoadingTemplates(false);
  };

  const filterByLabelId = (value: number) => {
    let filteredTemplates = templates;
    if (value > 0) {
      filteredTemplates = templates.filter(
        (template: TemplatesType) => template.label?.id == value
      );
    }
    setTemplateOptions(
      filteredTemplates.map((template: TemplatesType) => ({
        value: template.id,
        label: template.name,
      }))
    );
  };

  function getDocumentNameById(
    documents: TemplatesType[],
    id: number
  ): string | undefined {
    const document = documents.find((doc) => doc.id === id);
    return document ? document.name : undefined;
  }

  const [generating, setGenerating] = useState<boolean>(false);

  // GENERATE FUNCTON
  const generateDocuments = async (templateIds: number[], context: any) => {
    setGenerating(true);
    const cookies = new Cookies();
    const accounts = cookies.get("accounts");
    await axios({
      method: "post",
      url: "/api/v1/stats/journeys",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user?.access_token,
      },
    })
      .then(async (res) => {
        cookies.set("journey_id", res.data["id"], { path: "/" });
        await multipleDocuments(templateIds, context);
      })
      .catch((err) => {
        handleHttpError(err);
      })
      .finally(() => setGenerating(false));
  };

  return (
    <Drawer
      title="Templating"
      onClose={() => {
        setOpenTemplate(false);
        fullReset();
      }}
      open={openTemplate}
      destroyOnClose
    >
      <Flex vertical gap="middle">
        <Text>
          Generate documents from Gen AI extracted data using our template
          engine for efficient results.
        </Text>
        <Spin size="small" spinning={loadingLibs}>
          <Select
            placeholder="Select Library"
            style={{ width: "100%" }}
            onChange={(value: number) => {
              setLibraryId(value);
              getTemplates(value);
            }}
            options={libraries}
          />
        </Spin>
        <Spin size="small" spinning={loadingTemplates}>
          <Select
            disabled={libraryId === 0}
            value={labelId}
            placeholder="Select Label"
            style={{ width: "100%" }}
            onChange={(value: number) => {
              setLabelId(value);
              filterByLabelId(value);
            }}
            options={labels}
          />
        </Spin>
        <Spin size="small" spinning={loadingTemplates}>
          <Select
            disabled={libraryId === 0}
            placeholder="Select Template"
            style={{ width: "100%" }}
            onChange={(value: number) => {
              if (
                !selectedTemplates
                  .map((template) => template.id)
                  .includes(value)
              ) {
                setSelectedTemplates([
                  ...selectedTemplates,
                  { id: value, name: getDocumentNameById(templates, value) },
                ]);
              } else {
                message.info("Template already selected");
              }
            }}
            options={templateOptions}
          />
        </Spin>

        <Flex gap="small" align="center" justify="flex-end">
          <Text>{`${account?.name || "Account"} templates only?`}</Text>
          <Switch
            size="small"
            value={isAccountOnly}
            onChange={(checked: boolean) => setIsAccountOnly(checked)}
          />
        </Flex>

        {selectedTemplates.length > 0 && (
          <Space direction="vertical" size="small">
            <Divider orientation="left">Selected Templates</Divider>

            {selectedTemplates.map((template) => (
              <Flex
                gap="small"
                justify="space-between"
                align="center"
                style={{
                  backgroundColor: "#f5f5f5",
                  padding: 10,
                  borderRadius: 4,
                }}
              >
                <Text>{template.name}</Text>
                <Button
                  size="small"
                  type="text"
                  danger
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() =>
                    setSelectedTemplates((prevIds) =>
                      prevIds.filter((prevId) => prevId.id !== template.id)
                    )
                  }
                />
              </Flex>
            ))}

            <Divider />

            <Button
              loading={generating}
              block
              type="primary"
              disabled={selectedTemplates.length === 0}
              onClick={() =>
                generateDocuments(
                  selectedTemplates.map(
                    (template: TemplatesType) => template.id
                  ),
                  formData?.attributes
                )
              }
            >
              Generate
            </Button>
          </Space>
        )}
      </Flex>
    </Drawer>
  );
};

export default AiTemplates;

// FUNCTIONS

// Main Function
const callAPI = async (endpoint: string): Promise<any> => {
  //  Get Localstorage Data
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const access_token = user?.access_token;

  return await axios
    .get(endpoint, { headers: { Authorization: "Bearer " + access_token } })
    .then((response: any) => {
      const status = response.status;
      const data = response.data;
      console.log(`${status} - Success Response from ${endpoint}`);
      console.log({ response });
      return {
        status,
        data,
        response,
      };
    })
    .catch((err: any) => {
      const error = err.response?.data;
      const status = error?.status;
      console.log(`${status} - Failed Response from ${endpoint}`);
      console.log({ err });
      return {
        status,
        error,
        response: err,
      };
    });
};

// LIST LIBRARIES
export const listLibraries = async (): Promise<any> => {
  return await callAPI("/api/v1/libraries");
};

// LIST LABELS
export const listLabels = async (): Promise<any> => {
  return await callAPI("/api/v1/labels");
};

// LIST TEMPLATES
export const listTemplates = async (
  id: number
  //   account_id?: number,
  //   labelId?: number
): Promise<any> => {
  let endpoint = `/api/v1/templates?library_id=${id}`;
  //   if (account_id) endpoint = `${endpoint}&account_id=${account_id}`;
  //   if (labelId) endpoint = `${endpoint}&label_id=${labelId}`;
  return await callAPI(endpoint);
};
