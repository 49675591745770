import { AuditOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Button, Drawer, Space, Form } from "antd";
import CapacityForm from "./FormContent";
import ReportQueue from "./ReportQueue";

const Reports = ({ data, paloAltoKey }) => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  const DEFAULT_REPORT = "capacity";

  const [reportMode, setReportMode] = useState<string>(DEFAULT_REPORT);

  const [form] = Form.useForm();
  const onReset = () => {
    form.resetFields();
    setReportMode(DEFAULT_REPORT);
    onQueue();
  };

  const [myQueueId, setMyQueueId] = useState<string | null>(null);
  const [showQueue, setShowQueue] = useState<boolean>(false);

  const onQueue = () => {
    setShowQueue(!showQueue);
  };

  return (
    <>
      <Button
        // disabled={!selectedIds?.Sites}
        size="small"
        icon={<AuditOutlined />}
        onClick={showDrawer}
      >
        Reports
      </Button>

      <Drawer
        title="Palo Alto SD-WAN Reports"
        width={"30%"}
        onClose={closeDrawer}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onQueue}>
              {showQueue ? "Close Queue" : "Open Queue"}
            </Button>
            <Button onClick={onReset}>Reset</Button>
          </Space>
        }
      >
        {showQueue ? (
          <ReportQueue paloAltoKey={paloAltoKey} myQueueId={myQueueId} />
        ) : (
          <CapacityForm
            paloAltoKey={paloAltoKey}
            data={data}
            form={form}
            reportMode={reportMode}
            setReportMode={setReportMode}
            setMyQueueId={setMyQueueId}
            setShowQueue={setShowQueue}
          />
        )}
      </Drawer>
    </>
  );
};

export default Reports;
