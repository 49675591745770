import React from "react";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { exportAsExcel } from "../../../../../utils/exportAsExcel";

// example of payloads:
// {tabname: [{key: value}]}

const ExportToExcel = (payloads: any) => {
  console.log("payloads", payloads);
  const exportToExcel = async () => {
    const isCreated = await exportAsExcel(payloads.payloads);
    isCreated ? console.log("File Downloaded") : console.log("Failed Download");
  };

  return (
    <Button
      disabled={!Object.keys(payloads)}
      size="small"
      icon={<CloudDownloadOutlined />}
      onClick={exportToExcel}
    >
      Export to Excel
    </Button>
  );
};

export default ExportToExcel;
