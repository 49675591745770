import { Button, Flex, UploadFile } from "antd";
import React, { useState } from "react";
import TempBanner from "./TempBanner";
import { CloudDownloadOutlined, CloudUploadOutlined } from "@ant-design/icons";
import CardComp from "./CardComp";
import UploadFilesComp from "./UploadFilesComp";
import RetrievedDataComp from "./RetrievedDataComp";
import FeedbackComp from "./FeedbackComp";
import ReportFormComp from "./ReportFormComp";

const SHOWBANNER = true;

const DataExtractorPage = () => {
  const [STEP, setSTEP] = React.useState(1);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [siteId, setSiteId] = useState<string | undefined>(undefined);
  const [retrievalId, setRetrievalId] = useState<string | undefined>(undefined);
  const [product, setProduct] = useState<string | undefined>("SDWAN");
  const [subProduct, setSubProduct] = useState<string | undefined>(undefined);
  const [uploading, setUploading] = useState(false);
  const [response, setResponse] = useState<UploadResponse | undefined>(
    undefined
  );

  const reset = () => {
    setSTEP(1);
    setFileList([]);
    setSiteId(undefined);
    setRetrievalId(undefined);
    setProduct("SDWAN");
    setSubProduct(undefined);
    setResponse(undefined);
    setUploading(false);
  };

  console.log({ response });

  interface UploadResponse {
    message: string;
    siteId: string;
    file_names: string[];
  }
  return (
    <Flex
      vertical
      gap="middle"
      align="center"
      justify="center"
      style={{ display: "flex", width: "100vh" }}
    >
      <h1 className="colorIndigo">Gen AI Data Extractor</h1>
      {SHOWBANNER && <TempBanner />}
      {STEP === 1 && (
        <>
          <Flex
            gap="middle"
            align="flex-start"
            justify="center"
            style={{ width: "100%" }}
          >
            <>
              <CardComp
                icon={<CloudUploadOutlined />}
                title="Upload Files"
                inputPlaceholder="Enter Site Id"
                onInputBlur={(value) => setSiteId(value)}
                buttonText="Select Files"
                onButtonClick={() => setSTEP(2)}
                disabledButton={
                  siteId === undefined ||
                  siteId === "" ||
                  !subProduct ||
                  !product
                }
                disabled={retrievalId !== undefined && retrievalId !== ""}
                setProduct={setProduct}
                setSubProduct={setSubProduct}
                product={product}
                subProduct={subProduct}
                inputValue={siteId}
              />

              <CardComp
                icon={<CloudDownloadOutlined />}
                title="Retrieve Data"
                buttonText="Retrieve"
                inputPlaceholder="Enter Site Id"
                onInputBlur={(value) => setRetrievalId(value)}
                onButtonClick={() => setSTEP(3)}
                disabledButton={retrievalId === undefined || retrievalId === ""}
                disabled={siteId !== undefined && siteId !== ""}
                setProduct={setProduct}
                setSubProduct={setSubProduct}
                product={product}
                subProduct={subProduct}
                inputValue={retrievalId}
              />
            </>
          </Flex>
          <Button disabled={uploading} onClick={reset} danger type="text">
            Reset
          </Button>
        </>
      )}
      {STEP === 2 && (
        <>
          <UploadFilesComp
            response={response}
            setResponse={setResponse}
            uploading={uploading}
            setUploading={setUploading}
            fileList={fileList}
            siteId={siteId}
            retrievalId={retrievalId}
            product={product}
            subProduct={subProduct}
            setFileList={setFileList}
            reset={reset}
          />
          {/* <FeedbackComp siteId={siteId} uniqueId={retrievalId} /> */}
        </>
      )}
      {STEP === 3 && (
        <>
          <RetrievedDataComp
            retrieval_id={retrievalId}
            siteId={siteId}
            reset={reset}
          />
          {/* <FeedbackComp siteId={siteId} uniqueId={retrievalId} /> */}
        </>
      )}
      <Flex vertical>
        <ReportFormComp siteId={siteId} />
        {STEP === 3 ||
          (STEP == 2 && (
            <FeedbackComp siteId={siteId} uniqueId={retrievalId} />
          ))}
      </Flex>
    </Flex>
  );
};

export default DataExtractorPage;
