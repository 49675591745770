import React from "react";
import { Button, Drawer } from "antd";
import { CustomerServiceOutlined } from "@ant-design/icons";

const ReportButton: React.FC<{
  siteId: string | undefined;
}> = ({ siteId, uniqueId }) => {
  const buttonStyle: React.CSSProperties = {
    // position: "fixed",
    // right: "10px", // Adjust this value to position it closer to the scrollbar
    // top: "80%",
    // transform: "translateY(-50%) rotate(90deg)", // Rotate the button 90 degrees
    // zIndex: 1000, // Ensure it stays on top of other elements
    // transformOrigin: "right center", // Adjust the origin to keep it near the scrollbar
  };

  const [open, setOpen] = React.useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        type="link"
        icon={<CustomerServiceOutlined />}
        style={buttonStyle}
        onClick={showDrawer}
      >
        Gen AI Support Form
      </Button>
      <Drawer title="Gen AI Support Form" onClose={onClose} open={open}>
        <iframe
          src="https://forms.office.com/pages/responsepage.aspx?id=iFbzpwCcXk26QSnxRjd6sGrg3zhBFC9KntzRgEwcge1UNVhWQkdJTFFKNUtZT1dQWTdRRUpWTTlZTi4u&route=shorturl"
          style={{ width: "100%", height: "100%", border: "none" }}
          title="External Page"
        />
      </Drawer>
    </>
  );
};

export default ReportButton;
