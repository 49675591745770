import React, { useState } from "react";
import { ColorPicker, Divider, Slider, Space, Tooltip } from "antd";
import type { ColorPickerProps, GetProp } from "antd";
import { BgColorsOutlined } from "@ant-design/icons";

type Color = GetProp<ColorPickerProps, "value">;

type Values = [number, number, number, number, number];

type Strings = [string, string, string, string];

const ColourSlider = ({
  thresholdValues,
  setThresholdValues,
  coloursHex,
  setColoursHex,
}) => {
  const thresholdNames = ["Low", "Normal", "Warning", "Critical"];

  const handleSliderChange = (newValue: Values) => {
    // Value Limiters
    if (newValue[1] > newValue[2] - 1) newValue[1] = newValue[2] - 1;
    if (newValue[2] < newValue[1] + 1) newValue[2] = newValue[1] + 1;
    if (newValue[2] > newValue[3] - 1) newValue[2] = newValue[3] - 1;
    if (newValue[3] < newValue[2] + 1) newValue[3] = newValue[2] + 1;
    if (newValue[3] > newValue[4] - 1) newValue[3] = newValue[4] - 1;

    setThresholdValues([0, ...newValue.slice(1, -1), 100]);
  };

  const generateMarks = (values) => {
    const marks = {};
    values.forEach((val) => {
      marks[val] = {
        style: {
          transform: "translateY(-40px) translateX(-50%)",
        },
        label: `${val}`,
      };
    });
    return marks;
  };

  const SliderLabel = ({ index }) => {
    return (
      <div
        style={{
          position: "absolute",
          left: `${(thresholdValues[index] + thresholdValues[index + 1]) / 2}%`,
          transform: "translateX(-50%)",
        }}
      >
        <Space size="small" align="center" direction="vertical">
          <small>
            <strong>{thresholdNames[index]}</strong>
          </small>

          <ColorPicker
            // size="small"
            defaultValue={coloursHex[index]}
            onChangeComplete={(color) =>
              setColoursHex((prev: Strings) => {
                const newColour = [...prev];
                newColour[index] = color.toHexString(); // Change only this entry
                return newColour;
              })
            }
          >
            <Tooltip
              color={coloursHex[index]}
              title={`Change ${thresholdNames[index]} Colour`}
              overlayInnerStyle={{ color: getContrastColor(coloursHex[index]) }}
            >
              <BgColorsOutlined style={{ color: coloursHex[index] }} />
            </Tooltip>
          </ColorPicker>
        </Space>
      </div>
    );
  };

  return (
    <div style={{ position: "relative", width: "100%", marginTop: 24 }}>
      <Slider
        range
        value={thresholdValues}
        onChange={handleSliderChange}
        marks={generateMarks(thresholdValues)}
        trackStyle={[
          { backgroundColor: coloursHex[0] }, // First segment
          { backgroundColor: coloursHex[1] }, // Second segment
          { backgroundColor: coloursHex[2] }, // Third segment
          { backgroundColor: coloursHex[3] }, // Fourth segment
        ]}
        tooltip={{ open: false }}
      />
      <div
        style={{
          position: "absolute",
          top: "20px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {thresholdValues[1] !== 0 && <SliderLabel index={0} />}
        <SliderLabel index={1} />
        <SliderLabel index={2} />
        <SliderLabel index={3} />
      </div>

      <br />
      <Divider />
    </div>
  );
};

export default ColourSlider;

function getContrastColor(hexColor: string): string {
  // Remove the hash symbol if present
  hexColor = hexColor.replace("#", "");

  // Convert hex to RGB
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Calculate the luminance of the color
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return black or white depending on the luminance
  return luminance > 0.5 ? "#000000" : "#FFFFFF";
}

// Example usage
const hexCode = "#3498db";
const contrastColor = getContrastColor(hexCode);
console.log(`The contrast color for ${hexCode} is ${contrastColor}`);
