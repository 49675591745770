import {
  CloseCircleOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Card, Button, Tabs, Form, Input, Flex, message } from "antd";
import React, { useCallback, useState, useEffect } from "react";
import ButtonDrop from "./Buttons/ButtonDrop";

interface FormDisplayProps {
  siteId: string;
  originalData: any;
  formData: any;
  setFormData: (data: any) => void;
  changedValues: Record<string, any>;
  setChangedValues: (values: Record<string, any>) => void;
  userAddedAttributes: any;
  setUserAddedAttributes: (count: number) => void;
}

const FormDisplay: React.FC<FormDisplayProps> = ({
  siteId,
  originalData,
  formData,
  setFormData,
  changedValues,
  setChangedValues,
  userAddedAttributes,
  setUserAddedAttributes,
}) => {
  const [form] = Form.useForm();
  const [newPairs, setNewPairs] = useState({});
  const [newTabs, setNewTabs] = useState<string[]>([]);
  const [activeKey, setActiveKey] = useState("1");
  const [tabs, setTabs] = useState<string[]>([]);

  useEffect(() => {
    if (formData) {
      const tabOrder = formData?.tabs || [];
      const tabsPayload = Object.keys(formData?.attributes || {});
      setTabs([
        ...tabOrder,
        ...tabsPayload.filter((item) => !tabOrder.includes(item)),
      ]);
    }
  }, [formData]);

  useEffect(() => {
    if (formData)
      setFormData((prevData: any) => ({
        ...prevData,
        reports: {
          ...prevData.reports,
          edited_attributes: Object.keys(changedValues)?.length || 0,
          added_attibutes: userAddedAttributes || 0,
        },
      }));
  }, [changedValues, userAddedAttributes]);

  const handleAddKeyValuePair = (category: string) => {
    const newKey = prompt("Enter new attribute:");
    if (newKey) {
      // Check if the key already exists
      if (
        Object.keys(formData?.attributes[category])
          .map((key) => key.toLowerCase())
          .includes(newKey.toLowerCase())
      )
        message.error(`Attribute ${newKey} already exists on tab ${category}.`);
      else {
        // Add new attribute if not exists
        setFormData((prevData: any) => {
          return {
            ...prevData,
            attributes: {
              ...prevData.attributes,
              [category]: {
                ...prevData.attributes[category],
                [newKey]: "",
              },
            },
          };
        });
        setNewPairs((prevNewPairs) => {
          if (prevNewPairs[category] && prevNewPairs[category][newKey]) {
            return prevNewPairs;
          }
          return {
            ...prevNewPairs,
            [category]: {
              ...prevNewPairs[category],
              [newKey]: true,
            },
          };
        });
        setUserAddedAttributes((prevCount: number) => prevCount + 1);
      }
    }
  };

  const handleDeleteKeyValuePair = (category, key) => {
    setFormData((prevData: any) => {
      const updatedCategory = { ...prevData.attributes[category] };
      delete updatedCategory[key];
      return {
        ...prevData,
        attributes: { ...prevData.attributes, [category]: updatedCategory },
      };
    });
    setNewPairs((prevNewPairs) => {
      const updatedNewPairs = { ...prevNewPairs[category] };
      delete updatedNewPairs[key];
      return {
        ...prevNewPairs,
        [category]: updatedNewPairs,
      };
    });
    setUserAddedAttributes((prevCount: number) => prevCount - 1);
  };

  const handleAddCustomTab = () => {
    const newTabName = prompt("Enter new tab name:");

    if (newTabName) {
      // Check if the tab already exists
      if (
        Object.keys(formData.attributes)
          .map((key) => key.toLowerCase())
          .includes(newTabName.toLowerCase())
      ) {
        const lowerkeys = Object.keys(formData.attributes).map((key) =>
          key.toLowerCase()
        );
        const index = lowerkeys.indexOf(newTabName.toLowerCase());
        setActiveKey(`${index + 1}`);
        message.error(
          `Tab ${Object.keys(formData.attributes)[index]} already exists.`
        );
      } else {
        // Add new tab if not exists
        setNewTabs((prevTabs) => [...prevTabs, newTabName]);
        setFormData((prevData: any) => ({
          ...prevData,
          attributes: {
            ...prevData.attributes,
            [newTabName]: {},
          },
        }));

        setActiveKey((prevTabs) => {
          if (prevTabs.includes(newTabName)) {
            return prevTabs;
          }
          const newTabKey = `${Object.keys(formData.attributes).length + 1}`;
          return newTabKey; // Set the new tab as active
        });
      }
    }
  };

  const handleDeleteCustomTab = (index: number) => {
    setActiveKey("1");
    setFormData((prevData: any) => {
      const updatedAttributes = { ...prevData.attributes };
      const deletedTabName = Object.keys(updatedAttributes)[index];
      delete updatedAttributes[deletedTabName];
      const updatedTabs = prevData.tabs.filter((_, i) => i !== index);
      setNewTabs((prevTabs) =>
        prevTabs.filter((item) => item !== deletedTabName)
      );
      setTabs(updatedTabs); // Update the tabs state here
      return { ...prevData, attributes: updatedAttributes, tabs: updatedTabs };
    });
  };

  const onValuesChange = (changed, allValues) => {
    setChangedValues((prevValues) => ({
      ...prevValues,
      ...changed,
    }));

    // Update formData with the new values
    setFormData((prevData) => {
      const updatedAttributes = { ...prevData.attributes };

      Object.keys(changed).forEach((key) => {
        // Use the activeKey to determine the current category
        const category = tabs[activeKey - 1];

        if (category) {
          updatedAttributes[category] = {
            ...updatedAttributes[category],
            [key]: changed[key],
          };
        }
      });

      return {
        ...prevData,
        attributes: updatedAttributes,
      };
    });
  };

  // Ensure formData and formData.attributes are defined before mapping

  const tabItems = formData?.tabs
    ? tabs?.map(
        (tab: string, index: number) =>
          formData.attributes[tab] && {
            label: (
              <Flex gap="1px">
                {tab}
                {newTabs.includes(tab) && (
                  <Button
                    size="small"
                    type="text"
                    icon={<CloseOutlined style={{ fontSize: "8px" }} />}
                    onClick={() => handleDeleteCustomTab(index)}
                  />
                )}
              </Flex>
            ),
            key: (index + 1).toString(),
            children: (
              <Card
                style={{
                  marginTop: "-20px",
                  borderTop: "none",
                  borderRadius: 0,
                }}
              >
                <Form
                  variant="filled"
                  key={tab}
                  form={form}
                  onValuesChange={onValuesChange}
                >
                  {Object.entries(formData.attributes[tab]).map(
                    ([key, value]) => (
                      <Form.Item name={key} label={key} key={key}>
                        <Flex
                          gap="small"
                          align="center"
                          justify="space-between"
                        >
                          <Input
                            name={key}
                            data-category={tab}
                            defaultValue={value}
                          />
                          {newPairs[tab] && newPairs[tab][key] && (
                            <Button
                              type="link"
                              danger
                              icon={<CloseCircleOutlined />}
                              onClick={() => {
                                handleDeleteKeyValuePair(tab, key);
                              }}
                            >
                              {/* <small>Delete</small> */}
                            </Button>
                          )}
                        </Flex>
                      </Form.Item>
                    )
                  )}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => handleAddKeyValuePair(tab)}
                      style={{ width: "100%", marginBottom: "20px" }}
                      icon={<PlusOutlined style={{ fontSize: "8px" }} />}
                    >
                      <small>Attribute</small>
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            ),
          }
      )
    : [];

  tabItems.push({
    label: (
      <small>
        <PlusOutlined />
      </small>
    ),
    key: "add",
    children: <></>,
  });

  return (
    <Card
      style={{ width: "100%" }}
      title={`Extracted Data | ${siteId}`}
      extra={
        <div style={{ display: "flex", gap: "small" }}>
          {/* <ButtonRaw original={originalData} data={formData} />
          <ButtonExport data={formData?.attributes} />
          <ButtonSave formData={formData} siteId={siteId} />
          <ButtonTemplate /> */}
          <ButtonDrop original={originalData} data={formData} siteId={siteId} />
        </div>
      }
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "large" }}>
        {formData?.attributes && (
          <Tabs
            size="small"
            style={{ width: "100%" }}
            activeKey={activeKey}
            type="card"
            onChange={(key) => {
              if (key === "add") {
                handleAddCustomTab();
              } else {
                setActiveKey(key);
              }
            }}
            items={tabItems}
          />
        )}
      </div>
    </Card>
  );
};

export default FormDisplay;
